const light = {
  COLORS: {
    HEADER_BACKGROUND: "#DFDFDF",
    HEADER_BACKGROUND_HOVER: "#EFEFEF",

    PAGE_BACKGROUND: "#FFFFFF",
    PAGE_SEPARATOR: "#6F6F6F",

    CARD_BACKGROUND: "#F0F0F0",
    CARD_SECONDARY: "#E2E2E2",
    CARD_SHADOW: "#A6A6A6",
    CARD_TRANSPARENCY: "rgba(222, 222, 222, 0.7)",

    TEXT_DEFAULT: "#2f2f2f",
    TEXT_SECONDARY: "#72727F",
    TEXT_HIGHLIGHT: "#03A9F4",
    TEXT_SUB_HIGHLIGHT: "#4FC3F7",

    LINKEDIN: "#0072b1",
    GITHUB: "#010101",
    GMAIL: "#E54033",
    OUTLOOK: "#0F6CBD",
    WHATSAPP: "#25D366",
    TELEGRAM: "#0088cc"
  },
};

export default light;
