const dark = {
  COLORS: {
    HEADER_BACKGROUND: "#1E1E1E",
    HEADER_BACKGROUND_HOVER: "#353535",

    PAGE_BACKGROUND: "#080808",
    PAGE_SEPARATOR: "#979797",

    CARD_BACKGROUND: "#1E1E1E",
    CARD_SECONDARY: "#343434",
    CARD_SHADOW: "#000000",
    CARD_TRANSPARENCY: "rgba(0, 0, 0, 0.6)",

    TEXT_DEFAULT: "#E8EAED",
    TEXT_SECONDARY: "#E0E0E0",
    TEXT_HIGHLIGHT: "#304FFE",
    TEXT_SUB_HIGHLIGHT: "#536DFE",

    LINKEDIN: "#0072b1",
    GITHUB: "#010101",
    GMAIL: "#E54033",
    OUTLOOK: "#0F6CBD",
    WHATSAPP: "#25D366",
    TELEGRAM: "#0088cc"
  },
};

export default dark;
